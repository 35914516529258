import SocketConnection from './socket'
import {ChatEventType, ConnectionNameSpace, ConvoEventData, ConvoEventType} from "../dto";


function EventHandler(){

    const handle = async (convoEvent:ConvoEventData)=>{
        switch (convoEvent.type){
            case ConvoEventType.CHAT_EVENT : {
             switch (convoEvent.chatEventType) {
                 case ChatEventType.CHAT_LOADED:{

                    const lastModifiedAt =  window.localStorage.getItem(`${convoEvent.botId}_'last_notified_at'`)
                    const lastModifiedTime = lastModifiedAt? new Date(lastModifiedAt).getTime() : null
                     if(!lastModifiedTime || lastModifiedTime < new Date().setHours(new Date().getHours() - 1)){
                        await SocketConnection.emit({
                             namespace: ConnectionNameSpace.CONVO_EVENT,
                             event: convoEvent
                         })
                         window.localStorage.setItem(`${convoEvent.botId}_'last_notified_at'`, new Date().toISOString())
                     }
                     break;
                 }
             }
             break;
            }

        }
    }

    return {
        handle
    }

}

export default EventHandler();